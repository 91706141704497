@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');

.from-home {
    height: 100vh;
    color: white;
    text-align: center;
    font-family: 'Noto Sans Thai', sans-serif;
}

::-webkit-scrollbar {
    width: 0;
    display: none;
}
.background {
    width: 100%;
    position: relative;
}

.img-bg {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    object-fit: cover;
}

.text-header-home {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.418);
}

.layout-text-header-home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-header p {
    font-family: 'Poppins', sans-serif !important;
    height: 6vw;
}

.section-header-home {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #646b72;

}

.section-header-home span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.section-header-home span p:nth-child(2) {
    font-family: 'Roboto', sans-serif !important;
    padding-top: 1%;
}


.from-body-home {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 4%;
}

.text-about-production {
    width: 25%;
    text-align: left;
}
.text-about-production span {
    display: flex;
    gap: 1%;
    font-size: 2vw;
    font-weight: bold;
}

.text-about-production span p {
    font-family: 'Poppins', sans-serif !important;
}

.img-about-production {
    width: 35vw;
    /* height: 25vw; */
    border-radius: 1vw;
    object-fit: contain;
}

.from-footer-home {
    width: 100%;
}

.footer-header {
    /* height: 75vh; */
    background-color: black;
    padding: 50px;
}

.layout-footer-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.layout-footer-header span {
    width: 35%;
    padding: 50px;
    display: flex;
}
.icon-section {
    width: 6vw;
    height: 6vw;
    object-fit: cover;
}
.layout-footer-header span label p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
}
.layout-footer-header span label p:nth-child(2) {
    font-size: 16px;
}

.footer-footer {
    height: 25vh;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.img-footer-home {
    width: 4vw;
}