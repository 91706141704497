@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&family=Roboto:wght@700&display=swap');

.from-viewproduct {
    width: 100%;
    background-color: black;
    color: white;
    padding: 5% 4%;
    font-family: 'Noto Sans Thai', sans-serif;
}

.layout-header-viewproduct {
    width: 70%;
    margin-top: 50px;
    background-color: #19191a;
    padding: 20px;
    border-radius: calc(20px - 3px);
    margin-left: auto;
    margin-right: auto;
}

.layout-header-body-viewproduct {
    display: flex;
    justify-content: center;
    gap: 3%;
}

.img-header-viewproduct {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-viewproduct {
    border-radius: calc(20px - 3px);
}

.details-header-viewproduct {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.layout-name-viewproduct {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    line-height: 50px;
}

.layout-name-viewproduct p:nth-child(1) {
    font-size: 3.5vw;
    font-family: 'Kanit', sans-serif !important;
}

.layout-price-viewproduct {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    flex-grow: .5;
}

.layout-price-viewproduct p {
    font-family: 'Roboto', sans-serif !important;
}

.layout-pay-viewproduct button {
    border-radius: calc(15px - 3px);
}

.layout-header-footer-viewproduct {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.slider {
    width: 74%;
    height: 200px;
    display: flex;
    align-items: center;
    gap: 1.5%;
    overflow: auto;
    padding: 10px;
}

.img-more {
    border-radius: calc(15px - 3px);
    width: 200px;
}

.img-view-more {
    width: 50%;
    border-radius: calc(20px - 3px);
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    color: #F7CE68;
}

.slider-button:hover {
    color: #fc9c0d;
    transition: 0.3s;
}

.slider-button-left {
    left: 1vw;
}

.slider-button-right {
    right: 1vw;
}


.layout-body-viewproduct {
    width: 70%;
    margin-top: 50px;
    background-color: #19191a;
    padding: 20px;
    border-radius: calc(20px - 3px);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.header-body-viewproduct {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-body-viewproduct label {
    text-align: right;
}

.body-viewproduct {
    padding: 15px 10px;
}

.layout-footer-viewproduct {
    width: 70%;
    margin-top: 50px;
    background-color: #19191a;
    padding: 20px;
    border-radius: calc(20px - 3px);
    margin-left: auto;
    margin-right: auto;
}

.iframe-youtube iframe {
    width: 100%;
    height: 590px;
    border-radius: calc(20px - 3px);
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-pay-footer {
    width: 28%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.pay-product {
    position: absolute;
    width: 25%;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(10px);
    padding-top: 100px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    opacity: 0;
    transition: .5s ease;
    margin-left: 74vw;
    z-index: -1;
}

.active-pay-product {
    background-color: transparent;
    backdrop-filter: blur(10px);
    left: -10px;
    opacity: 1;
    z-index: 1;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    transition: .5s ease;
    width: 25%;
    height: 100vh;
    padding-top: 100px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 75vw;
    position: fixed;
}

.payment {
    font-size: 15px;
    width: 30%;
    transition: .3s ease-in-out;
    font-weight: bold;
}

.active-payment {
    padding: 5px;
    border-radius: 8px;
    transition: .3s ease-in-out;
    background-image: linear-gradient(62deg, #fc9c0d 50%, #F7CE68 100%);
}

.payment:hover {
    background-image: linear-gradient(62deg, #fc9c0d 50%, #F7CE68 100%);
    padding: 5px;
    border-radius: 8px;
    transition: .3s ease-in-out;
}