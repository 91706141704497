@import url('https://fonts.googleapis.com/css2?family=Inter&family=Noto+Sans+Thai:wght@300&display=swap');

.from-navbar {
    position: relative;
    width: 100%;
    padding: 10px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    height: 60px;
    z-index: 999;
    /* background: linear-gradient(180deg, rgba(10,10,10,1) 33%, rgba(89,89,89,.8) 100%); */
    background: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);
    font-family: 'Noto Sans Thai', sans-serif;

}

.navbar-logo {
    max-width: 40%;
}

.navbar-home {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;

    position: relative;
    display: inline-block;
    letter-spacing: 1px;
}

.navbar-home::before,
.navbar-home::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffd60a;
    transform: scaleX(0);
    transition: transform .25s;
}

.navbar-home::before {
    top: -3px;
    transform-origin: left;
}

.navbar-home::after {
    bottom: -3px;
    transform-origin: right;
}

.navbar-home:hover::before,
.navbar-home:hover::after {
    transform: scaleX(1);
}

.active-navbar-home::before,
.active-navbar-home::after {
    transform: scaleX(1);
}

.icon-navbar,
.icon-login {
    display: none;
}

.section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 2vw;
    color: white;
    padding: .5vw;
    border-radius: 1vw;
    gap: 1vw;
}

.navbar-login {
    color: white;
    text-decoration: none;
    font-size: 16px;
}

.navbar-login span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
    color: white;
}

.navbar-login span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
    color: #ffd60a;
}

.navbar-login:hover span {
    padding-right: 25px;
}

.navbar-login:hover span:after {
    opacity: 1;
    right: 0;
}

.menu {
    display: none;
}

.skill {
    position: relative;
}

.outer {
    position: absolute;
}

.image-user {
    width: 1.75vw;
    height: 1.75vw;
    margin-top: .4vw;
    margin-left: .4vw;
}

#skillSVG {
    width: 2.8vw;
    height: 2.8vw;
    transform: rotate(-100deg);
}

.fonts-text,
.text-name,
.text-name-logo {
    font-family: 'Inter', sans-serif;
}