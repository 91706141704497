// @media only screen and (min-width: 801px) and (max-width: 1920px) {}
// @media only screen and (min-width: 320px) and (max-width: 800px) {}

//? css navbar
.from-navbar {
    @media only screen and (min-width: 0px) and (max-width: 800px) {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .text-name-logo {
            display: none;
        }

        .icon-navbar {
            display: none;
        }

        .menu {
            display: block;
        }

        .icon-menu {
            color: white;
        }

        .section {
            width: 100%;
            height: 100vh;
            display: flex;
            position: absolute;
            flex-direction: column;
            align-content: center;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            top: 60px;
            left: 30%;
            transition: .5s ease;
            opacity: 0;
            background-color: transparent;
            backdrop-filter: blur(5px);
            margin-left: 70vw;
        }

        .active {
            left: 10px;
            opacity: 1;
            z-index: 1;
            align-content: center;
            align-items: flex-start;
            justify-content: flex-start;
            transition: .5s ease;
            height: 100vh;
            padding: 20px;
            margin-left: 70vw;

            & .text-name {
                display: none;
            }

            & .outer {
                width: 26px;
                height: 26px;

                & .image-user {
                    margin-top: 6px;
                    margin-left: 6px;
                    width: 24px;
                    height: 24px;
                }
            }

            & #skillSVG {
                width: 40px;
                height: 40px;
            }

            & .navbar-home {
                font-size: 15px;

                & p {
                    display: none;
                }

                & .icon-navbar {
                    font-size: 10px;
                    display: block;
                    padding: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.11);
                    border-radius: 5px;
                }
            }

            & .navbar-login {
                font-size: 15px;

                & span {
                    display: none;
                }

                & .icon-navbar {
                    font-size: 12px;
                    display: block;
                    padding: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.11);
                    border-radius: 5px;
                }
            }

            & .icon-login,
            & .icon-logout {
                position: absolute;
                top: 80%;
                left: 25px;
                font-size: 10px;
                display: block;
                padding: 10px;
                border: 1px solid rgba(255, 255, 255, 0.11);
                border-radius: 5px;
            }

            & .navbar-home::before,
            & .navbar-home::after {
                display: none;
            }
        }

        .bookmark {
            display: none;
        }
    }
}

//? css home
.from-home {
    @media only screen and (min-width: 0px) and (max-width: 800px) {
        .layout-text-header-home {
            // line-height: 40px !important;
        }

        .text-header {
            font-size: 24px !important;
            line-height: -40px !important;
        }

        .text-header-center {
            font-size: 18px !important;
            line-height: 40px !important;
        }

        .section-header-home p {
            font-size: 15px !important;

            & span p:nth-child(2) {
                font-size: 15px !important;
                padding-top: 1%;
            }
        }

        .from-body-home {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 4%;
            padding: 20% 0;

            & .text-about-production {
                width: 260px;

                & p:nth-child(1) {
                    font-size: 20px !important;
                    height: 20px !important;
                }

                & span p:nth-child(1),
                & span p:nth-child(2) {
                    font-size: 23px !important;
                    margin-top: 0 !important;
                }

                & p:nth-child(3),
                & p:nth-child(4),
                & p:nth-child(5) {
                    font-size: 15px !important;
                    margin-top: 2.5vw !important;
                }
            }


            & .img-about-production {
                min-width: 260px;
                height: 260px;
            }
        }

        .footer-header {
            // height: 100vh;

            & p:nth-child(1) {
                font-size: 18px !important;
            }

            & p:nth-child(2) {
                font-size: 30px !important;
            }

            & .layout-footer-header {
                display: flex;
                flex-wrap: wrap;
                // flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: left;

                & span {
                    width: 280px;
                    padding: 10px;

                    & .icon-section {
                        width: 80px !important;
                        height: 80px !important;

                    }

                    & label p:nth-child(1) {
                        font-size: 19px !important;
                    }

                    & label p:nth-child(2) {
                        font-size: 15px !important;
                    }
                }
            }
        }

        .footer-footer {

            // height: 100vh;
            & .img-footer-home {
                width: 50px;
            }

            & p {
                font-size: 18px !important;
            }
        }

    }
}

.from-products {
    @media only screen and (min-width: 0px) and (max-width: 800px) {

        .text-header-products {
            & span {
                & .text-section-products {

                    & p:nth-child(1),
                    & p:nth-child(3) {
                        font-size: 24px !important;
                    }

                    & p:nth-child(2) {
                        font-size: 18px !important;
                    }
                }

                & .text-details-section {
                    & p {
                        font-size: 12px !important;
                    }
                }
            }
        }

        .layout-recommended-products {

            & .grid-list-recommended {
                display: flex !important;
                gap: 20px !important;
                overflow: hidden;
                overflow-x: scroll;
                justify-content: flex-start;
                transition: all .2s;

                & .card-recommended-products {
                    width: 220px;

                    & .img-recommended-products {
                        position: relative;
                        width: 100%;
                        overflow: hidden;

                        & img {
                            width: 100%;
                            height: 100%;
                        }
                    }


                    & .layout-details-recommended {
                        & p:nth-child(1) {
                            font-size: 24px !important;
                        }

                        & p:nth-child(3) {
                            font-size: 18px !important;
                        }
                    }
                }
            }
        }


        .text-sction-recommended,
        .text-sction-all {
            font-size: 20px !important;
        }

        .grid-list-all {
            & .card-all-products {

                & .img-all-products {
                    position: relative;
                    width: 100%;
                    overflow: hidden;

                    & img {
                        width: 100%;
                        height: 100%;
                    }
                }

                & .layout-details-all {
                    & p:nth-child(1) {
                        font-size: 24px !important;
                    }

                    & p:nth-child(3) {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}

.from-viewproduct {
    @media only screen {
        & .pay-product {
            position: absolute;
            width: 25%;
            height: 100vh;
            top: 0;
            right: 0;
            left: 0;
            background-color: transparent;
            backdrop-filter: blur(10px);
            padding-top: 100px;
            margin: 0 auto;
            padding-left: 15px;
            padding-right: 15px;
            color: white;
            opacity: 0;
            transition: .5s ease;
            margin-left: 74vw;
            z-index: -1;

            &.active-pay-product {
                background-color: transparent;
                backdrop-filter: blur(10px);
                left: -10px;
                opacity: 1;
                z-index: 1;
                align-content: center;
                align-items: flex-start;
                justify-content: flex-start;
                transition: .5s ease;
                width: 25%;
                height: 100vh;
                padding-top: 100px;
                margin: 0 auto;
                padding-left: 15px;
                padding-right: 15px;
                margin-left: 75vw;
                position: fixed;
            }

            & .icon-close-pay {
                cursor: pointer;
            }

            & .payment {
                font-size: 15px;
                width: 30%;
                transition: .3s;

                &.active {
                    background-image: linear-gradient(62deg, #fc9c0d 50%, #F7CE68 100%);
                    padding: 5px;
                    border-radius: 8px;
                    transition: .3s;
                }

                &:hover {
                    background-image: linear-gradient(62deg, #fc9c0d 50%, #F7CE68 100%);
                    padding: 5px;
                    border-radius: 8px;
                    transition: .3s;
                }
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 800px) {
        position: relative !important;

        .layout-header-viewproduct {
            width: 100%;

            & .layout-header-body-viewproduct {
                flex-direction: column;
                gap: 10px;

                & .details-header-viewproduct {
                    gap: 10px;

                    & .layout-name-viewproduct {
                        line-height: 20px;

                        & p:nth-child(1) {
                            font-size: 24px;
                        }

                        & p:nth-child(2) {
                            font-size: 15px !important;
                        }
                    }

                    & .layout-price-viewproduct {
                        line-height: 20px;

                        & p:nth-child(1) {
                            font-size: 15px !important;
                        }

                        & p:nth-child(2) {
                            font-size: 18px !important;
                        }
                    }

                    & .layout-pay-viewproduct {
                        & button {
                            border-radius: calc(10px - 3px);
                            font-size: 14px !important;
                        }

                        & p:nth-child(2) {
                            font-size: 10px !important;
                        }
                    }
                }
            }


        }

        .layout-body-viewproduct {
            width: 100%;

            & .header-body-viewproduct {
                & p:nth-child(1) {
                    font-size: 15px !important;
                    border-radius: calc(10px - 3px) !important;
                }

                & label {
                    & p:nth-child(1) {
                        font-size: 13px !important;
                    }

                    & p:nth-child(2) {
                        font-size: 10px !important;
                    }
                }
            }

            & .body-viewproduct {
                & label {
                    font-size: 13px !important;
                    gap: 10px !important;

                    & .icon-circle {
                        font-size: 6px !important;
                    }
                }
            }
        }

        .layout-footer-viewproduct {
            width: 100%;

            & .iframe-youtube {
                & iframe {
                    height: 250px;
                    max-height: 350px;
                }
            }
        }

        .layout-pay-footer {
            width: 60%;

            & .layout-pay-viewproduct {
                & button {
                    border-radius: calc(10px - 3px) !important;
                    font-size: 18px !important;
                }

                & p:nth-child(2) {
                    font-size: 10px !important;
                }
            }
        }

        .pay-product {
            position: absolute !important;
            width: 100% !important;
            height: auto;
            top: 0;
            right: 0;
            left: 0;
            background-color: transparent;
            backdrop-filter: blur(10px);
            padding-top: 100px;
            margin: 0 auto;
            padding-left: 15px;
            padding-right: 15px;
            color: white;
            opacity: 0;
            transition: .5s ease;
            margin-left: 0 !important;
            z-index: -1;

            &.active-pay-product {
                background-color: transparent;
                backdrop-filter: blur(10px);
                opacity: 1;
                z-index: 1;
                align-content: center;
                align-items: flex-start;
                justify-content: flex-start;
                transition: .5s ease;
                width: 100%;
                height: 100%;
                padding-top: 70px;
                padding-left: 15px;
                padding-right: 15px;

                & .pay-details {
                    padding-left: 15px !important;
                    padding-right: 15px !important;

                    & p:nth-child(1) {
                        font-size: 14px !important;
                    }

                    & .btn-choose-pay {
                        padding-left: 15px !important;
                        padding-right: 15px !important;

                        & .payment {
                            width: 40%;
                        }
                    }


                    & .card-choose-pay {
                        height: 100% !important;

                        & .card-img-qr {
                            max-width: 100% !important;
                            width: 50% !important;
                            height: 50% !important;


                            & .img-qr {
                                // width: 50% !important;
                                // height: 50% !important;
                                object-fit: cover !important;
                                object-position: top !important;
                            }
                        }

                        & .img-wallet {
                            width: 40% !important;
                        }
                    }

                }

                & .pay-details-script {
                    padding-left: 15px !important;
                    padding-right: 15px !important;

                    & p:nth-child(1) {
                        font-size: 14px !important;
                    }

                    & div p:nth-child(1),
                    p:nth-child(2) {
                        font-size: 18px !important;
                    }
                }
            }

        }
    }
}

.from-profile {
    @media only screen and (min-width: 0px) and (max-width: 800px) {
        .layout-profile {
            width: 100%;

            & .card-table {
                padding: 10px 10px !important;

                & .text-date {
                    font-size: 10px !important;
                    max-width: 20vw !important;
                }

                & .text-script {
                    font-size: 12px !important;
                    max-width: 40vw !important;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                }

                & .ip-scrip {
                    font-size: 11px !important;
                    max-width: 65vw !important;
                }

                & .icon-pen {
                    font-size: 11px !important;
                }

                & .input-one-ip {
                    width: 85px !important;
                    font-size: 11px !important;
                    padding: .5px 0;

                    &::placeholder {
                        font-size: 10px !important;
                    }
                }
            }
        }

        & .models {
            display: flex;
            justify-content: center;
            align-items: center;

            & .card-models {
                position: absolute;
                transform: translate(-50%, -50%);
                max-width: 100% !important;
                top: 50% !important;
                left: 50% !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);

                & .icon-warm {
                    font-size: 30px !important;
                }

                & p {
                    font-size: 12px !important;
                }

                & .btn-models {
                    font-size: 13px !important;
                }
            }
        }
    }
}